import Editor from './Editor';
import Component from './Component';

const DisplayOptions = {
  Editor,
  Component,
  name: 'Widget Subscriber Test',
  description: 'Subscribes to published dashboard events (from other widgets).'
}

export default DisplayOptions;