import React, {useEffect} from 'react';
import { useWidgetContext } from '../../useWidgetContext';
import useFitText from '../../components/useFitText'
import API from '../../../../../api';
import {useParams} from "react-router-dom";

const {executeQuery} = API;

const Component = () => {
  const { data, config, filterValues } = useWidgetContext();
  const {entityRef} = useParams();
  const value = data?.value || 0;
  const {fontSize, ref, parentRef} = useFitText(value);

  return (
    <div ref={parentRef} style={{width:'100%', height: '100%'}}>
      <div ref={ref} style={{
        fontSize: fontSize,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        lineHeight: 'normal',
        overflow: 'hidden',
      }}>
        {JSON.stringify({config, filterValues}, null, 2)}
      </div>
    </div>
  );
};

export default Component;
